import styles from "./layout.module.css";

export enum ESizes {
	Default,
	Large,
}

const Sizes = {
	[ESizes.Default]: "col-md-10 col-lg-8",
	[ESizes.Large]: "col-md-12 col-lg-10",
};

type LozengeProps = {
	children: any;
	size?: ESizes | ESizes.Default;
	className?: string;
};

const Lozenge = (props: LozengeProps) => {
	return (
		<div className={`${Sizes[props.size ?? ESizes.Default]} p-md-3 mx-auto mt-5 p-2 shadow-lg tablet:!mx-2 laptop:mb-12 ${styles.lozenge} ${props.className}`}>
			<div className="mb-lg-1 px-lg-5 mb-3 mt-3 px-2">{props.children}</div>
		</div>
	);
};

export default Lozenge;
