import Image from "next/image";

import Lozenge from "components/Layout/Lozenge";
import Title from "components/Title";
import NotFoundImage from "public/img/404.svg";

export default function NotFound() {
	return (
		<Lozenge>
			<Title title="Page not found" />
			<div className="text-center">
				<Image src={NotFoundImage} alt="Not found image" />

				<div className="fw-bold fs-5 mt-3">Sorry, we could not find that page.</div>
			</div>
		</Lozenge>
	);
}
