import { BackButton } from "./Buttons";

const Title = ({ title, isUnderlined = false }: { title: string; isUnderlined?: boolean }) => {
	return (
		<div className="row flex flex-col">
			<div className=" col-lg-5 tablet:-ml-4">
				<BackButton />
			</div>
			<div className="col-lg-6 ml-auto mr-auto text-center tablet:mt-5">
				<h1 className="d-inline-block">
					{title}
					{isUnderlined && (
						<hr
							style={{
								border: "2px solid #29B8D0",
								borderRadius: "5px",
								opacity: "1",
							}}
						/>
					)}
				</h1>
			</div>
		</div>
	);
};

export default Title;
